import React, { useCallback } from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import { Site } from '../../types/query';

type Props = {
  title?: string;
};

type Metadata = {
  title: string;
  url: string;
  description: string;
};

type QueryData = Site<Metadata>;

const Ogp: React.FC<Props> = props => {
  const { title } = props;
  const renderComponent = useCallback(
    (data: QueryData) => {
      const { siteMetadata } = data.site;
      const siteName = siteMetadata.title;
      return (
        <Helmet defaultTitle={siteName} titleTemplate={`%s | ${siteName}`}>
          {title != null && <title>{title}</title>}
          <meta name="description" content={siteMetadata.description} />
          <meta property="og:description" content={siteMetadata.description} />
          <meta property="og:title" content={siteName} />
          <meta property="og:url" content={siteMetadata.url} />
          <meta property="og:image" content={`${siteMetadata.url}ogp.png`} />
        </Helmet>
      );
    },
    [title]
  );
  return <StaticQuery query={query} render={renderComponent} />;
};

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        url
        description
      }
    }
  }
`;

export default Ogp;
