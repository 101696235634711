import React, { useCallback, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Site } from '../../types/query';
import DocumentHelper from '../../utils/DocumentHelper';
import styled from '../../utils/styled-components';

type Metadata = {
  title: string;
  url: string;
};

type QueryData = Site<Metadata>;

const getHref = (url: string, text: string) =>
  `https://twitter.com/intent/tweet?url=${url}&text=${text}`;

const TwitterButton: React.FC = () => {
  const render = useCallback(
    (data: QueryData) => (
      <Button
        href={getHref(data.site.siteMetadata.url, data.site.siteMetadata.title)}
      >
        <Icon src="/twitter.svg" alt="share" />
      </Button>
    ),
    []
  );
  useEffect(() => {
    DocumentHelper.importScript(
      'twitter-wjs',
      'https://platform.twitter.com/widgets.js'
    );
  }, []);
  return <StaticQuery query={query} render={render} />;
};

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        url
      }
    }
  }
`;

const Button = styled.a`
  font-size: 0;
  line-height: 0;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
  margin: 0;
`;

export default TwitterButton;
