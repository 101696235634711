import React from 'react';
import { CONTAINER_MAX_WIDTH, SMALL_VIEW_PORT } from '../../constants/style';
import theme from '../../constants/theme';
import styled, {
  createGlobalStyle,
  ThemeProvider
} from '../../utils/styled-components';
import BackToTop from '../BackToTop';
import Header from '../Header';
import Ogp from '../Ogp';
import TwitterButton from '../TwitterButton';

type Props = {
  children: React.ReactNode;
  showTagMenu?: boolean;
  color?: string;
  title?: string;
};

type StyledProps = Pick<Props, 'showTagMenu'>;

const Layout: React.FC<Props> = ({
  children,
  showTagMenu = false,
  color = '#000',
  title
}) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Ogp title={title} />
        <Header color={color} showTagMenu={showTagMenu} />
        <Wrapper>
          <Main showTagMenu={showTagMenu}>{children}</Main>
        </Wrapper>
        <Share>
          <TwitterButton />
        </Share>
        <BackToTop />
      </>
    </ThemeProvider>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #fafafa;
  }
`;

const Wrapper = styled.div`
  position: relative;
  max-width: ${CONTAINER_MAX_WIDTH};
  margin: 0 auto;
  padding: 0 1rem 3rem;
`;

const Main = styled.main<StyledProps>`
  position: relative;
  padding-top: ${({ showTagMenu }) => (showTagMenu ? '7.5rem' : '3.5rem')};

  @media (min-width: ${SMALL_VIEW_PORT}) {
    padding-top: ${({ showTagMenu }) => (showTagMenu ? '9rem' : '5rem')};
  }
`;

const Share = styled.div`
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  z-index: 9999;
  line-height: 1;
`;

export default Layout;
