import React, { useState, useCallback } from 'react';
import { graphql, Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import styled, { keyframes } from '../../utils/styled-components';

export type CardFragment = {
  fields: {
    slug: string;
  };
  frontmatter: {
    date: string;
    color: string;
    image: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
};

type Props = {
  item: CardFragment;
};

const Card: React.FC<Props> = props => {
  const { item } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const handleLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);
  return (
    <Wrapper>
      <Link to={item.fields.slug}>
        <ImageWrapper>
          <Img
            fluid={item.frontmatter.image.childImageSharp.fluid}
            fadeIn={false}
            onLoad={handleLoad}
          />
          <ImageEffect />
        </ImageWrapper>
      </Link>
      <Date>{item.frontmatter.date}</Date>
      <BackgroundWrapper aria-hidden="true" data-is-loaded={isLoaded}>
        <Background style={{ backgroundColor: item.frontmatter.color }} />
      </BackgroundWrapper>
    </Wrapper>
  );
};

export const query = graphql`
  fragment CardFragment on MarkdownRemark {
    fields {
      slug
    }
    frontmatter {
      date(formatString: "YYYY")
      color
      image {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

/* stylelint-disable */
const ImageEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 224, 130, 0.6),
    rgba(244, 143, 177, 0.6),
    rgba(144, 202, 249, 0.6)
  );
  background-size: 600% 600%;
  visibility: hidden;
  z-index: 1;
`;
/* stylelint-enable */

const ImageWrapper = styled.div`
  position: relative;
  transform: scale(1);
  transition: transform 0.2s ease-out;

  &:hover {
    transform: scale(0.98);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.2);

    > ${ImageEffect} {
      visibility: visible;
      animation: ${gradient} 20s ease infinite;
    }
  }
`;

const Date = styled.div`
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: -1rem;
  z-index: 10;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8rem;
  line-height: 1;
  letter-spacing: 0.2rem;
  transform: rotateZ(90deg);
  transform-origin: center top;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;

  &[data-is-loaded='true'] > ${Background} {
    animation: ${fadeOut} 0.5s ease-out 0.25s;
    animation-fill-mode: forwards;
  }
`;

export default Card;
