import React from 'react';
// @ts-ignore
import kebabCase from 'lodash.kebabcase';
import TagLink from '../TagLink';
import styled from '../../utils/styled-components';

export type TagObj = {
  name: string;
  path: string;
};

type Props = {
  item: TagObj;
};

export const createTag = (name: string, path?: string): TagObj => ({
  name,
  path: path != null ? path : `/tag/${kebabCase(name)}`
});

const Tag: React.FC<Props> = props => {
  const { item } = props;
  return (
    <Wrapper>
      <TagLink to={item.path}>{`#${item.name}`}</TagLink>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  margin: 0 0.5rem 0.75rem 0;
  line-height: 1.25;

  &:last-of-type {
    margin-right: 0;
  }
`;

export default Tag;
