export default class DocumentHelper {
  static importScript(
    id: string,
    src: string,
    async: boolean = true,
    onload: () => void = () => {}
  ) {
    if (document.getElementById(id) != null) {
      onload();
      return;
    }
    const el = document.getElementsByTagName('head')[0];
    if (el != null) {
      const newEl = document.createElement('script');
      newEl.id = id;
      newEl.src = src;
      newEl.async = async;
      newEl.onload = onload;
      el.appendChild(newEl);
    }
  }
}
