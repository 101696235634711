import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import styled, { keyframes } from '../../utils/styled-components';

type Props = {
  to: string;
  children: ReactNode;
};

const TagLink: React.FC<Props> = props => {
  const { to, children } = props;
  const getProps = ({ isCurrent }: { isCurrent: boolean }) =>
    isCurrent ? { 'data-is-active': 'true' } : {};
  return (
    <StyledLink to={to} getProps={getProps}>
      {children}
    </StyledLink>
  );
};

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  display: block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8rem;
  line-height: 1.75;
  transform: translateY(0);
  transition: color 0.2s ease-out, transform 0.2s ease-out;

  &:hover {
    color: currentColor;
    transform: translateY(-2px);
  }

  &[data-is-active='true'] {
    color: ${props => props.theme.primary};

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 1px;
      margin-bottom: -0.5rem;
      background: linear-gradient(45deg, #fb251b, #ffc2bf);
      background-size: 400% 400%;
      animation: ${gradient} 5s ease infinite;
    }

    &:hover {
      transform: translateY(0);
    }
  }
`;

export default TagLink;
