import React, { useCallback } from 'react';
import { graphql, StaticQuery } from 'gatsby';
// @ts-ignore
import kebabCase from 'lodash.kebabcase';
import { createTag } from '../Tag';
import TagList from '../TagList';
import styled from '../../utils/styled-components';

type Tag = {
  fieldValue: string;
};

type QueryData = {
  allMarkdownRemark: {
    tags: Tag[];
  };
};

const DEFAULT_TAG = createTag('ALL', '/');

const TagMenu: React.FC = () => {
  const render = useCallback((data: QueryData) => {
    const items = data.allMarkdownRemark.tags.map(tag => ({
      name: tag.fieldValue,
      path: `/tag/${kebabCase(tag.fieldValue)}`
    }));
    items.unshift(DEFAULT_TAG);
    return (
      <Wrapper>
        <Icon className="material-icons">filter_list</Icon>
        <TagList items={items} />
      </Wrapper>
    );
  }, []);
  return <StaticQuery query={query} render={render} />;
};

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      tags: group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
`;

const Icon = styled.span`
  margin-right: 0.5rem;
`;

export default TagMenu;
