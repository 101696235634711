import { graphql } from 'gatsby';
import React from 'react';
import Masonry from 'react-masonry-component';
import {
  LARGE_VIEW_PORT,
  MEDIUM_VIEW_PORT,
  SMALL_VIEW_PORT
} from '../../constants/style';
import { MarkdownRemarkEdge } from '../../types/query';
import styled from '../../utils/styled-components';
import Card, { CardFragment } from '../Card';

export type CardListFragment = CardFragment & {
  id: string;
};

type Props = {
  items: MarkdownRemarkEdge<CardListFragment>[];
};

const CardList: React.FC<Props> = props => {
  const { items } = props;
  return (
    <Masonry
      options={{
        columnWidth: '.card-sizer',
        itemSelector: '.card-item',
        transitionDuration: 200,
        horizontalOrder: true,
        percentPosition: true
      }}
      disableImagesLoaded
    >
      <CardWrapper className="card-sizer" aria-hidden="true" />
      {items.map(({ node }) => (
        <CardItem key={node.id} className="card-item">
          <Card item={node} />
        </CardItem>
      ))}
    </Masonry>
  );
};

export const query = graphql`
  fragment CardListFragment on MarkdownRemarkEdge {
    node {
      id
      ...CardFragment
    }
  }
`;

const CardWrapper = styled.div`
  width: 100%;

  @media (min-width: ${SMALL_VIEW_PORT}) {
    width: 50%;
  }

  @media (min-width: ${MEDIUM_VIEW_PORT}) {
    width: 33.33%;
  }

  @media (min-width: ${LARGE_VIEW_PORT}) {
    width: 25%;
  }
`;

const CardItem = styled(CardWrapper)`
  padding: 0 5%;
  margin-bottom: 2rem;

  @media (min-width: ${SMALL_VIEW_PORT}) {
    padding: 0 2%;
  }
`;

export default CardList;
