import React, { useEffect, useMemo, useRef } from 'react';
import useScrollTop from '../../utils/hooks/useScrollTop';
import styled from '../../utils/styled-components';
import Button from '../Button';

const VISIBLE_THRESHOLD = 50;

const BackToTop: React.FC = () => {
  const scrollTop = useScrollTop();
  const isVisible = useMemo(() => scrollTop > VISIBLE_THRESHOLD, [scrollTop]);
  const moveTo = useRef<MoveTo>();
  const target = useRef<HTMLDivElement | null>(null);
  const unregister = useRef<MoveTo.unregisterTriggerType>(() => {});
  useEffect(() => {
    const importMoveTo = async () => {
      const { default: MoveTo } = await import('moveto');
      moveTo.current = new MoveTo();
      if (target.current != null) {
        unregister.current = moveTo.current.registerTrigger(target.current);
      }
    };
    importMoveTo();
    return () => {
      if (typeof unregister.current === 'function') {
        unregister.current();
      }
    };
  }, []);
  return (
    <Wrapper aria-hidden="true" data-is-visible={isVisible} ref={target}>
      <Button>
        <span className="material-icons">expand_less</span>
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 40px;
  height: 40px;
  z-index: 9999;
  cursor: pointer;
  color: #000;
  border: 2px solid #000;
  border-radius: 50%;
  opacity: 0;
  transform: translateY(0);
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;

  &[data-is-visible='true'] {
    opacity: 1;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

export default BackToTop;
