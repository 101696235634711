import React from 'react';
import Tag, { TagObj } from '../Tag';
import styled from '../../utils/styled-components';

type Props = {
  items: TagObj[];
};

const TagList: React.FC<Props> = props => {
  const { items } = props;
  return (
    <Wrapper>
      {items.map(item => (
        <Tag key={item.path} item={item} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export default TagList;
