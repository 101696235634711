import React from 'react';
import styled from '../../utils/styled-components';

type Props = {
  children: React.ReactNode;
};

const Button: React.FC<Props> = props => {
  const { children } = props;
  return (
    <StyledButton role="button" tabIndex={0}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.div`
  display: inline-block;
  cursor: pointer;
  outline: none;
  font-size: 0;
  line-height: 1;
`;

export default Button;
