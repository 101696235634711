import { graphql, StaticQuery } from 'gatsby';
import React, { useCallback } from 'react';
import { animated, config, useTrail } from 'react-spring';
import { SMALL_VIEW_PORT } from '../../constants/style';
import { Site } from '../../types/query';
import styled from '../../utils/styled-components';

type Props = {
  color: string;
};

type Metadata = {
  title: string;
};

type QueryData = Site<Metadata>;

const LogoWrapper: React.FC<Props & Metadata> = props => {
  const { color, title } = props;
  const titleArr = title.split('');
  const trail = useTrail(titleArr.length, {
    config: config.stiff,
    opacity: 1,
    y: 0,
    from: {
      opacity: 0,
      y: -10
    }
  });
  return (
    <StyledLogo>
      {trail.map(({ y, ...rest }, index) => {
        const text = titleArr[index];
        const key = `${text}${index}`;
        return (
          <animated.div
            key={key}
            style={{
              ...rest,
              color,
              transform: y.interpolate(
                (ny: number) => `translate3d(0, ${ny}px, 0)`
              )
            }}
          >
            {text}
          </animated.div>
        );
      })}
    </StyledLogo>
  );
};

const Logo: React.FC<Props> = props => {
  const render = useCallback(
    (data: QueryData) => (
      <LogoWrapper title={data.site.siteMetadata.title} {...props} />
    ),
    []
  );
  return <StaticQuery query={query} render={render} />;
};

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const StyledLogo = styled.h1`
  display: flex;
  margin: 0;
  font-size: 2rem;
  letter-spacing: 2px;
  color: currentColor;

  @media (min-width: ${SMALL_VIEW_PORT}) {
    font-size: 2.5rem;
  }
`;

export default Logo;
