import { Link } from 'gatsby';
import React from 'react';
import { CONTAINER_MAX_WIDTH, SMALL_VIEW_PORT } from '../../constants/style';
import styled, { keyframes } from '../../utils/styled-components';
import Logo from '../Logo';
import TagMenu from '../TagMenu';

type Props = {
  color: string;
  showTagMenu: boolean;
};

const Header: React.FC<Props> = props => {
  const { color, showTagMenu } = props;
  return (
    <StyledHeader>
      <HeadingWrapper>
        <Link to="/">
          <Logo color={color} />
        </Link>
        <StyledLink to="/me">
          <Me>me?</Me>
        </StyledLink>
      </HeadingWrapper>
      {showTagMenu && (
        <TagMenuWrapper>
          <TagMenu />
        </TagMenuWrapper>
      )}
    </StyledHeader>
  );
};

const glitch = keyframes`
  0% {
    transform: skewX(0deg);
  }

  25% {
    transform: skewX(80deg);
  }

  75% {
    transform: skewX(-20deg);
  }

  90% {
    transform: skewX(0deg);
  }

  100% {
    transform: skewX(0deg);
  }
`;

const StyledHeader = styled.header`
  position: fixed;
  width: 100%;
  max-width: ${CONTAINER_MAX_WIDTH};
  top: 0;
  left: 50%;
  padding: 1rem 1rem 0;
  transform: translateX(-50%);
  z-index: 9999;

  @media (min-width: ${SMALL_VIEW_PORT}) {
    padding-top: 1.5rem;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const Me = styled.h2`
  margin: 0;
  color: currentColor;
`;

const StyledLink = styled(Link)`
  color: rgb(0, 0, 0);

  &:hover {
    animation: ${glitch} 0.2s ease-out;
  }
`;

const TagMenuWrapper = styled.div`
  margin-top: 1rem;
`;

export default Header;
