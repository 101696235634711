import React from 'react';
import styled, { keyframes } from '../../utils/styled-components';

type Props = {
  children: React.ReactNode;
  maskColor?: string;
};

const MaskText: React.FC<Props> = ({ children, maskColor = '#000' }) => (
  <Wrapper>
    {children}
    <Mask style={{ backgroundColor: maskColor }} />
  </Wrapper>
);

const mask = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(101%);
  }
`;

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  overflow: hidden;
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  animation: ${mask} 0.5s ease-out 0.75s;
  animation-fill-mode: forwards;
`;

export default MaskText;
